import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getSUBSCRIPTIONSTableStructure";
export const UPDATE_TABLE_DATA = "updateSUBSCRIPTIONSTableData";
export const EXPORT_TABLE_DATA = "exportSUBSCRIPTIONSTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setSUBSCRIPTIONSTableStructure";
export const SET_TABLE_DATA = "setSUBSCRIPTIONSTableData";
export const SET_TABLE_FILTER = "setSUBSCRIPTIONSTableFilter";
export const SET_TABLE_PAGE = "setSUBSCRIPTIONSTablePage";
export const SET_TABLE_PER_PAGE = "setSUBSCRIPTIONSTablePerPage";
export const SET_TABLE_SORT_BY = "setSUBSCRIPTIONSTableSortBy";
export const SET_EXPORT_LINK = "setSUBSCRIPTIONSExportLink";
export const SET_CLIENTS = "setSUBSCRIPTIONSClients";
export const SET_RULES = "setSUBSCRIPTIONSRules";
export const EDIT_RULES = "editSUBSCRIPTIONSRules";
export const REMOVE_RULES = "removeSUBSCRIPTIONSRules";
export const SET_RULES_SKUS = "setSUBSCRIPTIONSRulesSKUS";
export const EDIT_RULES_SKUS = "editSUBSCRIPTIONSRulesSKUS";
export const REMOVE_RULES_SKUS = "removeSUBSCRIPTIONSRulesSKUS";
export const SET_SELECTED_CLIENT = "setSUBSCRIPTIONSSelectedClient";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  clients: [],
  rules: [],
  rulesSKUS: [],
  selectedClient: null,
};

const getters = {
  getSUBSCRIPTIONSTableProperties(state) {
    return state.table_structure.properties;
  },
  getSUBSCRIPTIONSTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getSUBSCRIPTIONSSelectedClient(state) {
    return state.selectedCountry;
  },
  getSUBSCRIPTIONSClients(state) {
    return state.clients;
  },
  getSUBSCRIPTIONSRules(state) {
    return state.rules;
  },
  getSUBSCRIPTIONSRulesSKUS(state) {
    return state.rulesSKUS;
  },
  getSUBSCRIPTIONSTableState(state) {
    return state.table_state;
  },
  getSUBSCRIPTIONSTableHeaders(state) {
    return state.table_structure.columns;
  },

  getSUBSCRIPTIONSTableFilters(state) {
    return state.table_structure.filters;
  },

  getSUBSCRIPTIONSTableData(state) {
    return state.table_data.data;
  },

  getSUBSCRIPTIONSExportUrl(state) {
    return state.export_link;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/order_management/subscriptions")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .finally((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/order_management/subscriptions/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .finally((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/order_management/subscriptions/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .finally((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_SELECTED_CLIENT](state, client) {
    state.selectedCountry = client;
  },
  [SET_RULES](state, data) {
    if (Array.isArray(data)) state.rules = data;
    else state.rules.push(data);
  },
  [EDIT_RULES](state, data) {
    state.rules.splice(data.lastElementIndex, 1, data.newItem);
  },
  [REMOVE_RULES](state, item) {
    const index = state.rules.findIndex((elem) => elem.id === item.id);
    if (index > -1) {
      state.rules.splice(index, 1);
    }
  },
  [SET_RULES_SKUS](state, data) {
    if (Array.isArray(data)) state.rulesSKUS = data;
    else state.rulesSKUS.push(data);
  },
  [EDIT_RULES_SKUS](state, data) {
    state.rulesSKUS.splice(data.lastElementIndex, 1, data.newItem);
  },
  [REMOVE_RULES_SKUS](state, item) {
    const index = state.rulesSKUS.findIndex((elem) => elem.id === item.id);
    if (index > -1) {
      state.rulesSKUS.splice(index, 1);
    }
  },
  [SET_CLIENTS](state, clients) {
    state.clients = clients;
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },

  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PER_PAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORT_BY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  // [SET_TABLE_SORT_ORDER](state, sortOrder) {
  //   state.table_state.sortOrder = sortOrder;
  // },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
