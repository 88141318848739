import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const GET_REPORT_DATA = "getREPORTData";
export const SHOW_REPORT_DATA = "showREPORTData";
export const EXPORT_REPORT_DATA = "exportREPORTData";

// mutation types
export const SET_REPORT_PROPERTIES = "setREPORTProperties";
export const SET_REPORT_HAS_EXPORT = "setREPORTHasExport";
export const SET_REPORT_FILTERS = "setREPORTFilters";
export const SET_REPORT_FILTERS_RESULT = "setREPORTFiltersResult";
export const SET_EXPORT_LINK = "setREPORTExportLink";

const state = {
  properties: {},
  hasExport: false,
  exportLink: undefined,
  filters: [],
  filtersResult: null,
};

const getters = {
  /**
   * for table title, description
   * */
  getREPORTProperties(state) {
    return state.properties;
  },
  getREPORTHasExport(state) {
    return state.hasExport;
  },
  getREPORTExportUrl(state) {
    return state.exportLink;
  },
  getREPORTFilters(state) {
    return state.filters;
  },
  getREPORTFiltersResult(state) {
    return state.filtersResult;
  },
};

const actions = {
  [GET_REPORT_DATA](context, url) {
    return new Promise((resolve) => {
      context.commit(SET_PAGE_LOADING, true);
      ApiService.post(`${url}`)
        .then(({ data }) => {
          context.commit(SET_REPORT_PROPERTIES, data.data.properties);
          context.commit(SET_REPORT_FILTERS, data.data.filters);
          data.data?.export && context.commit(SET_REPORT_HAS_EXPORT);
          resolve(data.data);
        })
        .catch((data) => {
          resolve(data.data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [SHOW_REPORT_DATA](context, { url, filtersData }) {
    return new Promise((resolve) => {
      context.commit(SET_PAGE_LOADING, true);
      ApiService.post(`${url}`, {
        filters: filtersData,
      })
        .then(({ data }) => {
          context.commit(SET_REPORT_FILTERS_RESULT, data.charts[0].values);
          resolve(data.charts);
        })
        .catch(({ data }) => {
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [EXPORT_REPORT_DATA](context, { dataUrl, payload }) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(`${dataUrl}/export`, payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_REPORT_PROPERTIES](state, properties) {
    state.properties = properties;
  },
  [SET_REPORT_HAS_EXPORT](state) {
    state.hasExport = true;
  },
  [SET_REPORT_FILTERS](state, filters) {
    state.filters = filters;
  },
  [SET_REPORT_FILTERS_RESULT](state, filters) {
    state.filtersResult = filters;
  },
  [SET_EXPORT_LINK](state, link) {
    state.exportLink = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
