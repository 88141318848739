import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const VERIFY_AUTH = "verifyAuthIntegration";
export const LOGIN = "loginIntegration";
export const LOGOUT = "logoutIntegration";
export const REGISTER = "registerIntegration";
export const UPDATE_PASSWORD = "updateUserIntegration";
export const GET_INITIAL = "getInitialIntegration";
// mutation types
export const PURGE_AUTH = "logOutIntegration";
export const SET_AUTH = "setUserIntegration";
export const SET_AUTH2 = "setUser2Integration";
export const SET_PASSWORD = "setPasswordIntegration";
export const SET_LOGO_INTEGRATION = "setLogoIntegration";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  logoIntegration: null,
};

const getters = {
  getSocketDataIntegration(state) {
    return state.user.socket;
  },
  currentUserIntegration(state) {
    return state.user;
  },
  isAuthenticatedIntegration(state) {
    return state.isAuthenticated;
  },
  isClientIntegration(state) {
    return state.user.is_client;
  },
  getLogoIntegration(state) {
    return state.logoIntegration;
  },
};

const actions = {
  [GET_INITIAL](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("auth/integration", credentials)
        .then(({ data }) => {
          context.commit(SET_LOGO_INTEGRATION, data);
          resolve(data);
        })
        .catch((error) => {
          resolve(error);
        });
    });
  },
  [LOGIN](context, credentials) {
    // context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.post("/auth/integration/login", credentials)
        .then(({ data }) => {
          if (data.data.status == "Active") {
            //
            context.commit(SET_AUTH, data);
            // context.commit(SET_PAGE_LOADING, false);
            resolve(data);
          } else {
            // let errorObj = {
            //   title: "User status error",
            //   text: "Your user is not active!",
            //   icon: "error",
            // };
            // context.commit(SET_ERROR, errorObj);
            context.commit(PURGE_AUTH);
            // context.commit(SET_PAGE_LOADING, false);
            resolve(data);
          }
        })
        .catch((error) => {
          resolve(error);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("/auth/integration/register", credentials)
        .then(({ data }) => {
          // context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      context.commit(SET_PAGE_LOADING, true);
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("verify")
          .then(({ data }) => {
            context.commit(SET_AUTH2, data);
            resolve(data);
          })
          .catch(() => {
            context.commit(PURGE_AUTH);
            resolve(false);
          });
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.post("profile/password/update", password)
      .then(({ data }) => {
        context.commit(SET_PASSWORD, data);
        context.commit(SET_PAGE_LOADING, false);
        return true;
      })
      .catch(() => {
        context.commit(SET_PAGE_LOADING, false);
        return false;
      });
  },
};

const mutations = {
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_AUTH2](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    // JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_LOGO_INTEGRATION](state, data) {
    state.logoIntegration = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
