import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getROLTableStructure";

// mutation types
export const SET_ROLES = "setROLERoles";
export const SET_PAGES = "setROLEPages";
export const SET_ACTIONS = "setROLEActions";
export const SET_COLUMNS = "setROLEColumns";
export const SET_ACTION_LABEL = "setROLEActionLabel";
export const SET_COLUMN_LABEL = "setROLEColumnLabel";
export const ADD_REMOVE_PAGE_TO_SELECTED_ROLE =
  "addAndRemovePageToSelectedRole";
export const ADD_REMOVE_ACTION_TO_SELECTED_PAGE =
  "addAndRemoveActionToSelectedPage";
export const ADD_REMOVE_COLUMN_TO_SELECTED_ACTION =
  "addAndRemoveColumnToSelectedAction";
export const SET_SELECTED_ROLE_DETAILS = "setROLESelectedRoleDetails";
export const SELECT_ALL_PAGES = "selectAllPages";
export const SELECT_ALL_ACTIONS = "selectAllActions";
export const SELECT_ALL_COLUMNS = "selectAllColumns";
export const CREATE_ROLE = "createRole";
export const EDIT_ROLE = "editeRole";
export const ONLY_RELATED_TOGGLE = "onlyRelatedToggleSelect";

const state = {
  roles: [],
  pages: [],
  actions: [],
  columns: [],
  actionLabel: "Select a page",
  columnLabel: "Select a action",
  selectedRoleDetails: {},
};

const getters = {
  getROLERoles(state) {
    return state.roles;
  },
  getROLEPages(state) {
    return state.pages;
  },
  getROLEActions(state) {
    return state.actions;
  },
  getROLEColumns(state) {
    return state.columns;
  },
  getROLEActionLabel(state) {
    return state.actionLabel;
  },
  getROLEColumnLabel(state) {
    return state.columnLabel;
  },
  getROLESelectedRoleDetails(state) {
    return state.selectedRoleDetails;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/authorizations/roles")
        .then(({ data }) => {
          context.commit(SET_ROLES, data.data.roles);
          context.commit(SET_PAGES, data.data.entities);
          context.commit(SET_ACTIONS, { page_id: null, actions: null });
          context.commit(SET_COLUMNS, { action_id: null, columns: null });
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [EDIT_ROLE](state, payload) {
    const { index, title, description } = payload;

    state.roles[index].title = title;
    state.roles[index].description = description;
  },
  [ONLY_RELATED_TOGGLE](state, payload) {
    const { action_id, isChecked } = payload;

    const { role_index, page_index } = state.selectedRoleDetails;

    const action_index = state.roles[role_index].entities[
      page_index
    ].permissions.findIndex((item) => item.name === action_id);

    state.roles[role_index].entities[page_index].permissions[
      action_index
    ].only_related = isChecked;
  },
  [CREATE_ROLE](state, data) {
    state.roles.push({ ...data });
  },
  [SELECT_ALL_COLUMNS](state, selectAll) {
    const { role_index, page_index, action_index } = state.selectedRoleDetails;

    if (selectAll) {
      state.roles[role_index].entities[page_index].permissions[
        action_index
      ].columns = state.columns.map((column) => {
        column.is_selected = true;
        return {
          ...column,
          is_selected: true,
        };
      });
    } else {
      state.columns.forEach((column) => {
        column.is_selected = false;
      });

      state.roles[role_index].entities[page_index].permissions[
        action_index
      ].columns.splice(0);
    }
  },
  [SELECT_ALL_ACTIONS](state, selectAll) {
    const { role_index, page_index } = state.selectedRoleDetails;
    if (selectAll) {
      state.roles[role_index].entities[
        page_index
      ].permissions = state.actions.map((action) => {
        action.is_selected = true;

        return {
          ...action,
          columns: action.columns.map((item) => ({
            ...item,
            is_selected: true,
          })),
        };
      });
    } else {
      state.actions.forEach((action) => {
        action.is_selected = false;
        action.only_related = false;
      });

      state.roles[role_index].entities[page_index].permissions.splice(0);
    }
  },
  [SELECT_ALL_PAGES](state, selectAll) {
    const { role_index } = state.selectedRoleDetails;
    if (selectAll) {
      state.roles[role_index].entities = state.pages.map((page) => {
        page.is_selected = true;

        return {
          ...page,
          permissions: page.permissions.map((permission) => ({
            ...permission,
            is_selected: true,
            columns: permission.columns.map((column) => ({
              ...column,
              is_selected: true,
            })),
          })),
        };
      });
    } else {
      state.pages.forEach((page) => {
        page.is_selected = false;
      });

      state.roles[role_index].entities.splice(0);
    }
  },
  [ADD_REMOVE_COLUMN_TO_SELECTED_ACTION](state, payload) {
    const { index, id, forAdding } = payload;
    const { role_index, page_index, action_index } = state.selectedRoleDetails;

    if (forAdding) {
      const localColumns = { ...state.columns[index], is_selected: true };

      state.roles[role_index].entities[page_index].permissions[
        action_index
      ].columns.push(localColumns);
    } else {
      const foundIndex = state.roles[role_index].entities[
        page_index
      ].permissions[action_index].columns.findIndex((item) => item.name === id);

      if (foundIndex > -1) {
        state.roles[role_index].entities[page_index].permissions[
          action_index
        ].columns.splice(foundIndex, 1);
      }
    }
  },
  [ADD_REMOVE_ACTION_TO_SELECTED_PAGE](state, payload) {
    const { index, id, forAdding } = payload;
    const { role_index, page_index } = state.selectedRoleDetails;

    if (forAdding) {
      const localActions = {
        ...state.actions[index],
        is_selected: true,
        only_related: false,
        columns: state.actions[index].columns.map((item) => ({
          ...item,
          is_selected: true,
        })),
      };
      state.roles[role_index].entities[page_index].permissions.push(
        localActions
      );
    } else {
      const foundIndex = state.roles[role_index].entities[
        page_index
      ].permissions.findIndex((item) => item.name === id);

      if (foundIndex > -1) {
        state.roles[role_index].entities[page_index].permissions.splice(
          foundIndex,
          1
        );
      }
    }
  },
  [ADD_REMOVE_PAGE_TO_SELECTED_ROLE](state, payload) {
    const { index, id, forAdding } = payload;
    const { role_index } = state.selectedRoleDetails;

    if (forAdding) {
      state.roles[role_index].entities.push({
        name: state.pages[index].name,
        title: state.pages[index].title,
        permissions: state.pages[index].permissions.map((item) => ({
          ...item,
          is_selected: true,
          only_related: false,
          columns: item.columns.map((column) => ({
            ...column,
            is_selected: true,
          })),
        })),
      });
    } else {
      const foundIndex = state.roles[role_index].entities.findIndex(
        (item) => item.name === id
      );

      if (foundIndex > -1) {
        state.roles[role_index].entities.splice(foundIndex, 1);
      }
    }
  },
  [SET_SELECTED_ROLE_DETAILS](state, obj) {
    state.selectedRoleDetails = {
      ...state.selectedRoleDetails,
      ...obj,
    };
  },
  [SET_ROLES](state, data) {
    state.roles = [...data];
    state.selectedRoleDetails = {};
  },
  [SET_PAGES](state, data) {
    state.pages = data.map((item) => ({
      ...item,
      is_selected: false,
    }));
  },
  [SET_ACTIONS](state, payload) {
    const { page_id, actions } = payload;
    state.actions = [];

    if (page_id) {
      const { role_index } = state.selectedRoleDetails;
      const page_index = state.roles[role_index].entities.findIndex(
        (item) => item.name === page_id
      );

      state.selectedRoleDetails.page_index = page_index;

      state.actions = actions.map((action) => ({
        ...action,
        columns: action.columns.map((item) => ({
          ...item,
          is_selected: false,
        })),
        is_selected: Boolean(
          state.roles[role_index].entities[page_index].permissions.find(
            (item) => item.name === action.name
          )
        ),
        only_related: Boolean(
          state.roles[role_index].entities[page_index].permissions.find(
            (item) => item.name === action.name
          )?.only_related
        ),
      }));
    }
  },
  [SET_COLUMNS](state, payload) {
    const { action_id, columns } = payload;

    state.columns = [];

    if (action_id) {
      const { role_index, page_index } = state.selectedRoleDetails;

      const action_index = state.roles[role_index].entities[
        page_index
      ].permissions.findIndex((item) => item.name === action_id);

      state.selectedRoleDetails.action_index = action_index;

      state.columns = columns.map((column) => ({
        ...column,
        is_selected: Boolean(
          state.roles[role_index].entities[page_index].permissions[
            action_index
          ].columns.find((item) => item.name === column.name)
        ),
      }));
    }
  },
  [SET_ACTION_LABEL](state, label) {
    state.actionLabel = label;
  },
  [SET_COLUMN_LABEL](state, label) {
    state.columnLabel = label;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
