/* eslint-disable no-console */

import { register } from "register-service-worker";

if (
  process.env.NODE_ENV === "production"
  // process.env.NODE_ENV !== "production"
) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    // old one
    // registered() {
    //   console.log("Service worker has been registered.");
    // },
    registered(registration) {
      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 15); // e.g. hourly checks
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
      // alert("new version is available!");
    },

    //old one
    // updated() {
    //   console.log("New content is available; please refresh.");
    //   // refresh page after new content loaded!
    //   // alert("new context exists updating!");
    //   window.location.refresh(true);
    //   window.location.reload(true);
    // },
    updated() {
      console.log("New content is available; please refresh.");
      // document.dispatchEvent(
      //   new CustomEvent("swUpdated", { detail: registration })
      // );
      window.location.reload(true);
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
