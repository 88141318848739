import Swal from "sweetalert2";
import { addItemMessage, successTitle } from "@/core/constants/globalVariables";

const swalAdded = () => {
  Swal.fire({
    title: `${successTitle}`,
    text: `${addItemMessage}`,
    icon: "success",
    showConfirmButton: false,
    timer: 2500,
  });
};

export default swalAdded;
