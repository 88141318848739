import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getWMSPACKINGTableStructure";
export const UPDATE_TABLE_DATA = "updateWMSPACKINGTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setWMSPACKINGTableStructure";
export const SET_TABLE_DATA = "setWMSPACKINGTableData";
export const SET_TABLE_FILTER = "setWMSPACKINGTableFilter";
export const SET_TABLE_PAGE = "setWMSPACKINGTablePage";
export const SET_TABLE_PERPAGE = "setWMSPACKINGTablePerPage";
export const SET_TABLE_SORTBY = "setWMSPACKINGTableSortBy";
export const SET_TABLE_SORTORDER = "setWMSPACKINGTableSortOrder";

const state = {
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  getWMSPACKINGTableProperties(state) {
    return state.table_structure.properties;
  },
  // getWMSPACKINGTablePermissions(state) {
  //   return state.table_structure.permissions;
  // },
  getWMSPACKINGTableState(state) {
    return state.table_state;
  },

  getWMSPACKINGTableHeaders(state) {
    return state.table_structure.columns;
  },

  getWMSPACKINGTableData(state) {
    return state.table_data.data;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/packing")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/packing/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
