import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_QC_DATA = "getQCData";

// mutation types
export const SET_QC_DATA = "setQCData";
export const SET_QC_RESULT = "setQCResult";

const state = {
  showResultContainer: false,
  skus: [
    {
      code: null,
      description: null,
      weight: null,
      cube: null,
      length: null,
      width: null,
      height: null,
      delivery_no: null,
      brand: null,
      category: null,
      sub_category: null,
      size: null,
      condition_type: null,
      image_url: "https://apitest.iqfulfillment.com/images/no-image.jpg",
      need_authentication: null,
      supplier: {
        supplier_id: null,
        supplier_name: null,
        country: null,
        state: null,
        city: null,
        address_1: null,
        address_2: null,
        postal_code: null,
        phone: null,
        email: null,
      },
    },
  ],
};

const getters = {
  getQCData(state) {
    return state.skus;
  },
  getQCResult(state) {
    return state.showResultContainer;
  },
};

const actions = {
  [UPDATE_QC_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/qc/search", payload)
        .then(({ data }) => {
          context.commit(SET_QC_DATA, data.skus);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          context.commit(SET_QC_DATA, null);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_QC_DATA](state, sku) {
    state.skus = sku;
  },
  [SET_QC_RESULT](state, value) {
    state.showResultContainer = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
