import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getINVENTBALANCETableStructure";
export const UPDATE_TABLE_DATA = "updateINVENTBALANCETableData";
export const EXPORT_TABLE_DATA = "exportINVENTBALANCETableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setINVENTBALANCETableStructure";
export const SET_TABLE_DATA = "setINVENTBALANCETableData";
export const SET_TABLE_FILTER = "setINVENTBALANCETableFilter";
export const SET_TABLE_PAGE = "setINVENTBALANCETablePage";
export const SET_TABLE_PERPAGE = "setINVENTBALANCETablePerPage";
export const SET_TABLE_SORTBY = "setINVENTBALANCETableSortBy";
export const SET_TABLE_SORTORDER = "setINVENTBALANCETableSortOrder";
export const SET_EXPORT_LINK = "setINVENTBALANCEExportLink";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  /**
   * for table title, description
   * */
  getINVENTBALANCETableProperties(state) {
    return state.table_structure.properties;
  },
  /**
   * for table permissions
   **/
  getINVENTBALANCETablePermissions(state) {
    return state.table_structure.permissions;
  },
  getINVENTBALANCETableState(state) {
    // console.log("table state is ", state.table_state);
    return state.table_state;
  },

  getINVENTBALANCETableHeaders(state) {
    return state.table_structure.columns;
  },

  getINVENTBALANCETableFilters(state) {
    return state.table_structure.filters;
  },

  getINVENTBALANCETableData(state) {
    return state.table_data.data;
  },

  getINVENTBALANCEExportUrl(state) {
    return state.export_link;
  },
  getINVENTBALANCEUploadType(state) {
    return state.table_structure.type;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    // console.log(payload);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/inventory_balances")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.post(
        "/warehouse_management/inventory_balances/datatable",
        payload
      )
        .then(({ data }) => {
          // console.log("data of datatable ", data);
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/inventory_balances/export",
        payload
      )
        .then(({ data }) => {
          // console.log("export data is ", data);
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
