import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: "mdi", // || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  // rtl: true,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#7e04b7",
        secondary: "#e8ecfa",
        accent: "#5d78ff",
        error: "#fd397a",
        info: "#5578eb",
        success: "#0abb87",
        warning: "#ffb822",
        background: "transparent",
      },
      dark: {
        primary: "#7E04B7",
        accent: "#FF4081",
        secondary: "#ffe18d",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
      },
    },
  },
};

export default new Vuetify(opts);
