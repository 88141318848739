import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const UPDATE_TABLE_STRUCTURE = "getWEBHOOKSTableStructure";
export const UPDATE_TABLE_DATA = "getWEBHOOKSTableData";
export const UPDATE_ACTIONS_DIALOG_SERVER_DATA = "getWEBHOOKSCreateData";

// mutation types
export const SET_TABLE_STRUCTURE = "setWEBHOOKSTableStructure";
export const SET_TABLE_DATA = "setWEBHOOKSTableData";
export const SET_TABLE_FILTER = "setWEBHOOKSTableFilter";
export const SET_TABLE_PAGE = "setWEBHOOKSTablePage";
export const SET_TABLE_PERPAGE = "setWEBHOOKSTablePerPage";
export const SET_TABLE_SORTBY = "setWEBHOOKSTableSortBy";
export const SET_ACTIONS_DIALOG_SERVER_DATA =
  "setWEBHOOKSActionsDialogServerData";

const state = {
  table_structure: {},
  table_data: {},
  actionsDialogServerData: null,
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  getWEBHOOKSTableState(state) {
    return state.table_state;
  },

  getWEBHOOKSTableHeaders(state) {
    return state.table_structure.columns;
  },

  getWEBHOOKSTableFilters(state) {
    return state.table_structure.filters;
  },

  getWEBHOOKSTablePermissions(state) {
    return state.table_structure.permissions;
  },

  getWEBHOOKSTableData(state) {
    return state.table_data.data;
  },

  getWEBHOOKSTableProperties(state) {
    return state.table_structure.properties;
  },

  getWEBHOOKSActionsDialogServerData(state) {
    return state.actionsDialogServerData;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/datahub/webhooks")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_ACTIONS_DIALOG_SERVER_DATA](context) {
    return new Promise((resolve) => {
      ApiService.post("datahub/webhooks/create")
        .then(({ data }) => {
          context.commit(SET_ACTIONS_DIALOG_SERVER_DATA, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/datahub/webhooks/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_ACTIONS_DIALOG_SERVER_DATA](state, actionsDialogServerData) {
    state.actionsDialogServerData = actionsDialogServerData;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
