import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getPAYMENTSTableStructure";
export const UPDATE_TABLE_DATA = "getPAYMENTSTableData";
export const UPDATE_CREATE_DATA = "getPAYMENTSCreateData";
export const UPDATE_DETAIL_DATA = "getPAYMENTSDetailData";
export const UPDATE_EDIT_DATA = "getPAYMENTSEditData";
export const EXPORT_TABLE_DATA = "exportPAYMENTSTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setPAYMENTSTableStructure";
export const SET_TABLE_DATA = "setPAYMENTSTableData";
export const SET_CREATE_DATA = "setPAYMENTSCreateData";
export const SET_DETAIL_DATA = "setPAYMENTSDetailData";
export const SET_EDIT_DATA = "setPAYMENTSEditData";
export const SET_TABLE_FILTER = "setPAYMENTSTableFilter";
export const SET_TABLE_PAGE = "setPAYMENTSTablePage";
export const SET_TABLE_PERPAGE = "setPAYMENTSTablePerPage";
export const SET_TABLE_SORTBY = "setPAYMENTSTableSortBy";
export const SET_TABLE_SORTORDER = "setPAYMENTSTableSortOrder";
export const SET_EXPORT_LINK = "setPAYMENTSExportLink";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  create_data: {},
  edit_data: {},
  detail_data: null,
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  /**
   * for table title, descripton
   * */
  getPAYMENTSTableProperties(state) {
    return state.table_structure.properties;
  },
  /**
   * for table permissions
   **/
  getPAYMENTSTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getPAYMENTSTableState(state) {
    // console.log("table state is ", state.table_state);
    return state.table_state;
  },
  getPAYMENTSTableHeaders(state) {
    return state.table_structure.columns;
  },

  getPAYMENTSTableFilters(state) {
    return state.table_structure.filters;
  },

  getPAYMENTSTableData(state) {
    return state.table_data.data;
  },

  getPAYMENTSCreateData(state) {
    return state.create_data;
  },
  getPAYMENTSDetailData(state) {
    return state.detail_data;
  },
  getPAYMENTSEditData(state) {
    return state.edit_data;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/payments")
        .then(({ data }) => {
          // console.log("suc", data);
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/payments/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_CREATE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/payments/create", payload)
        .then(({ data }) => {
          context.commit(SET_CREATE_DATA, data);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_DETAIL_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/payments/show", payload)
        .then(({ data }) => {
          if (data.success) {
            context.commit(SET_DETAIL_DATA, data.store_gateways);
          } else {
            context.commit(SET_DETAIL_DATA, null);
          }
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_EDIT_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.post("/stores/portal/payments/edit", payload)
        .then(({ data }) => {
          if (data.success) {
            context.commit(SET_EDIT_DATA, data);
          } else {
            context.commit(SET_EDIT_DATA, null);
          }
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/stores/portal/payments/export", payload)
        .then(({ data }) => {
          // console.log("export data is ", data);
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    // console.log("structure set ->", table_structure);
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_CREATE_DATA](state, create_data) {
    state.create_data = create_data;
  },
  [SET_DETAIL_DATA](state, detail_data) {
    state.detail_data = detail_data;
  },
  [SET_EDIT_DATA](state, edit_data) {
    state.edit_data = edit_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
