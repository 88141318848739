import ApiService from "@/core/services/api.service";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE =
  "getPUTAWAYRULEBASEDTEMPLATETableStructure";
export const UPDATE_TABLE_DATA = "updatePUTAWAYRULEBASEDTEMPLATETableData";
export const UPDATE_CREATE_TEMPLATE_STRUCTURE =
  "updatePUTAWAYRULEBASEDTEMPLATECreateTemplateStructure";
export const UPDATE_WAREHOUSE_SPECIFTC_DATA =
  "updatePUTAWAYRULEBASEDTEMPLATEWarehouseSpecificData";
export const STORE_NEW_TEMPLATE = "storePUTAWAYRULEBASEDTEMPLATE";
export const GET_ZONES_BASED_ON_GROUPS =
  "getPUTAWAYRULEBASEDTEMPLATEZonesBasedOnGroups";

// mutation types
export const SET_TABLE_STRUCTURE = "setPUTAWAYRULEBASEDTEMPLATETableStructure";
export const SET_TABLE_DATA = "setPUTAWAYRULEBASEDTEMPLATETableData";
export const SET_TABLE_FILTER = "setPUTAWAYRULEBASEDTEMPLATETableFilter";
export const SET_TABLE_PAGE = "setPUTAWAYRULEBASEDTETablePage";
export const SET_TABLE_PERPAGE = "setPUTAWAYRULEBASEDTEMPLATETablePerPage";
export const SET_TABLE_SORTBY = "setPUTAWAYRULEBASEDTEMPLATETableSortBy";
export const SET_CREATE_TEMPLATE_STRUCTURE =
  "setPUTAWAYRULEBASEDTEMPLATECreateTemplateStructure";
export const SET_WAREHOUSE_SPECIFTC_DATA =
  "setPUTAWAYRULEBASEDTEMPLATEWarehouseSpecificData";
export const SET_ZONES_BASED_ON_GROUPS =
  "settPUTAWAYRULEBASEDTEMPLATEZonesBasedOnGroups";

const state = {
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  create_template_form_structure: {
    zones_based_on_groups: null,
  },
};

const getters = {
  getPUTAWAYRULEBASEDTEMPLATETableProperties(state) {
    return state.table_structure.properties;
  },
  getPUTAWAYRULEBASEDTEMPLATETablePermissions(state) {
    return state.table_structure.permissions;
  },
  getPUTAWAYRULEBASEDTEMPLATETableState(state) {
    return state.table_state;
  },
  getPUTAWAYRULEBASEDTEMPLATETableHeaders(state) {
    return state.table_structure.columns;
  },
  getPUTAWAYRULEBASEDTEMPLATETableFilters(state) {
    return state.table_structure.filters;
  },
  getPUTAWAYRULEBASEDTEMPLATETableData(state) {
    return state.table_data.data;
  },
  getPUTAWAYRULEBASEDTEMPLATECreateFormProperties(state) {
    return state.create_template_form_structure;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/putaway_rule_based_templates")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/putaway_rule_based_templates/datatable",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_CREATE_TEMPLATE_STRUCTURE](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        `/warehouse_management/putaway_rule_based_templates/create`
      )
        .then(({ data }) => {
          context.commit(SET_CREATE_TEMPLATE_STRUCTURE, data);
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [UPDATE_WAREHOUSE_SPECIFTC_DATA](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(
        `/warehouse_management/putaway_rule_based_templates/warehouse_specific_data`,
        payload
      )
        .then(({ data }) => {
          context.commit(SET_WAREHOUSE_SPECIFTC_DATA, data);
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [STORE_NEW_TEMPLATE](context, payload) {
    const data = payload[0];
    const url = payload[1] ? "update" : "store";
    return new Promise((resolve) => {
      ApiService.post(
        `/warehouse_management/putaway_rule_based_templates/${url}`,
        data
      )
        .then(() => {
          swalAdded();
          resolve();
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [GET_ZONES_BASED_ON_GROUPS](context, payload) {
    return new Promise((resolve) => {
      ApiService.post(`/wms/warehouses/zones/search`, payload)
        .then(({ data }) => {
          context.commit(SET_ZONES_BASED_ON_GROUPS, data.zones);
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_CREATE_TEMPLATE_STRUCTURE](state, form_structure) {
    state.create_template_form_structure = {
      ...state.create_template_form_structure,
      ...form_structure,
    };
  },
  [SET_WAREHOUSE_SPECIFTC_DATA](state, warehouse_data) {
    state.create_template_form_structure = {
      ...state.create_template_form_structure,
      ...warehouse_data,
    };
  },
  [SET_ZONES_BASED_ON_GROUPS](state, zone_groups) {
    state.create_template_form_structure.zones_based_on_groups = zone_groups;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
